<template>
  <section class="panel"></section>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      image: "",
      promo_name: "",
      promo_picture: "",
      promo_location: "",
      banner_response: "",
      search_address: "",

      add_toggle: false,
      add_form: {
        address: "",
        delivery_zone: "",
        street_index: "",
      },

      add_popular: false,
      add_popular_field: "",

      change_popup_toggle: false,
    };
  },
  setup() {},
  methods: {
    async changeProductPosition(product, action) {
      // Что если сделать функционал кастомной сортировки продуктов полностью через vuex,
      // Сохранить при этом реактивность объектов,
      // а потом при запросе на сервер, подтягивать конфиг и делать вычисления на клиенте, идея неплохая

      let settings = {
        product: product,
        action: action,
      };
      settings;
      // this.SET_ADMIN_PRODUCTS_POSITION(settings);
    },
    // async saveProductPositions() {
    //   this.SAVE_ADMIN_PRODUCTS_POSITION(this.CATEGORY_PRODUCTS);
    // },
  },
  computed: {
    ...mapGetters([]),
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.promotion {
  margin-top: 20px;
}
.form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 30px;
  &__input {
    @include container;
    position: relative;
    width: 45%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 14px;
    font-size: 13px;
    border: none;
    outline: none;
    margin-bottom: 20px;
    &-block {
      max-width: 100%;
      width: 100%;
    }
    &::-webkit-input-placeholder {
      color: $second-black;
    }
    &::-moz-placeholder {
      color: $second-black;
    }
    &:-ms-input-placeholder {
      color: $second-black;
    }
    &::-ms-input-placeholder {
      color: $second-black;
    }
    &::placeholder {
      color: $second-black;
    }
  }
  &__title {
    margin-bottom: 20px;
  }
  &__button {
    padding: 20px;
    border-radius: 10px;
    max-width: 45%;
    width: 100%;
    color: $white;
  }
  &__add {
    background-color: turquoise;
  }
  &__delete {
    background-color: tomato;
  }
  .location__title {
    margin-bottom: 20px;
  }
  label {
    input {
      margin-left: 10px;
    }
  }
}
.addresses {
  margin-top: 30px;
  &__list {
    max-height: 50vh;
    overflow: auto;
  }
  &__item {
    @include container;
    margin-bottom: 20px;
    padding: 5px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  input {
    padding: 10px;
    border-radius: 10px;
    border: none;
  }
  &__input {
    background-color: $background;
    width: 45%;
    margin: 5px;
    &:first-child {
      width: 100%;
    }
  }
  button {
    border: none;
    border-radius: 10px;
    padding: 10px;
    width: 100%;
    color: #fff;
  }

  &__search {
    background-color: #fff;
    width: 100%;
    display: block;
  }
  &__delete {
    margin: 5px;
    background-color: tomato;
  }
  &__add {
    background-color: turquoise;
    margin: 10px 0;
  }
}
.admin-popular {
  margin-top: 20px;

  &__container {
    display: flex;
    flex-wrap: wrap;
  }
  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @include container;
    padding: 10px;
    margin-right: 20px;
    margin-bottom: 20px;
    &-container {
      @include container;
      padding: 10px;
      margin-bottom: 20px;
    }
  }
  &__image {
    max-width: 125px;
    max-height: 130px;
    height: 100%;
  }
  &__title {
    @include h3;
  }
  &__delete {
    background-color: tomato;
    border: none;
    border-radius: 10px;
    color: #fff;
    margin: 5px;
    padding: 10px;
    width: 100%;
  }
  &__add {
    border: none;
    border-radius: 10px;
    background-color: turquoise;
    padding: 10px;
    width: 100%;
    color: #fff;
    margin: 15px 0;
  }
  &__input {
    padding: 10px;
    border-radius: 10px;
    border: none;
    width: 100%;
    background-color: $background;
    margin: 5px;
  }
}
.config {
  &__title {
    @include h2;
  }
  &__list {
    display: flex;
    overflow: auto;
    margin-bottom: 20px;
  }
  &__item {
    max-width: 120px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    @include container;
    padding-bottom: 10px;
    margin-right: 10px;
    cursor: pointer;
    text-align: center;
  }
  &__image {
    max-width: 100px;
  }
}
.change-popup {
  &__title {
    @include h2;
    margin-bottom: 20px;
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
  }
  &__item {
    display: flex;
    margin: 10px;
    flex-direction: column;
    align-items: center;
    @include container;
    padding: 10px;
  }
  &__image {
    max-width: 120px;
    margin-bottom: 5px;
  }
  &__controls {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 10px 0;
  }
  &__control {
    padding: 7px;
    background-color: $accent;
    border-radius: 10px;
    cursor: pointer;
    color: #fff;
  }
  &__save {
    background-color: turquoise;
    padding: 10px;
    margin: 15px 0;
    margin-left: 10px;
    width: 100%;
    color: $white;
    border-radius: 10px;
    cursor: pointer;
  }
}
.admin-promocodes {
  &__title {
    @include h2;
  }
  &__list {
    display: flex;
    overflow: auto;
  }
  &__item {
    @include container;
    margin-right: 20px;
    padding: 7px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  &__change {
    @include container;
    background-color: $accent;
    padding: 5px;
    color: $white;
  }
}
</style>
